import React from 'react';
import PageMeta from '../components/common/PageMeta';
import ContactsForm from '../components/contact/ContactsForm';
import ReviewTwo from '../components/tabs/ReviewTabTwo';
import FooterSocial from '../layout/Footer/FooterSocial';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';

const RequestDemo = () => {
  return (
    <Layout>
      <PageMeta title='FilePulse | Request demo' description="Experience our solutions firsthand by scheduling a personalized demo and see how our secure file transfer and cloud services can benefit your business." />
      <Navbar navDark posAbsolute />
      <section
        className='sign-up-in-section bg-dark ptb-100'
        style={{
          background:
            "url('/assets/img/page-header-bg.svg')no-repeat bottom right",
        }}
      >
        <div className='container'>
          <div className='row align-items-center justify-content-between'>
            <ContactsForm />
          </div>
        </div>
      </section>
      {/* <FooterSocial /> */}
    </Layout>
  );
};

export default RequestDemo;
