import React from 'react';
import PageMeta from '../components/common/PageMeta';
import CtaFive from '../components/cta/CtaFive';

import FeatureFive from '../components/features/FeatureFive';
import Process from '../components/process/Process';
import TestimonialThree from '../components/testimonial/TestimonialThree';
import FeatureImgContentSix from '../components/cloudstoragegateway/features';
import FeaturesTwo from '../components/cloudstoragegateway/featurestwo';
import FeaturesThree from '../components/cloudstoragegateway/featuresthree';
import TrialBanner from "../components/trial/trialbanner";
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import HeroEightCustom from './HeroEightCustom';


const HomeAppTwo = () => {
    return (
        <Layout>
            <PageMeta title='Cloud storage gateway | FilePulse' description="Bridge your on-premise systems with cloud storage, ensuring secure, fast, and cost-effective data transfer with our cloud storage gateway solution." />
            <Navbar navDark />
            <HeroEightCustom>
                Cloud storage gateway
            </HeroEightCustom>
            <FeaturesThree />
            <FeaturesTwo />
            <FeatureImgContentSix />
            <TrialBanner />
            {/* <FeatureFive />
            <Process />
            <CtaFive /> */}
            <FooterOne footerLight />
        </Layout>
    );
};

export default HomeAppTwo;
