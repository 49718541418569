import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../common/SectionTitle';

const FeatureOne = () => {
  return (
    <>
      <section className='feature-promo ptb-120 bg-light'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-6 col-md-10'>
              <SectionTitle
                title='Our Features'
                description='For a more detailed and complete list of features see our documentation.'
                centerAlign
              />
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 p-0">
              <div className="single-service p-lg-5 p-4 text-center mt-3 border-bottom border-end">
                <div className="service-icon icon-center">
                  <img
                    src="/assets/img/service/coding.png"
                    alt="service icon"
                    width="65"
                    height="65"
                  />
                </div>
                <div className="service-info-wrap">
                  <h3 className="h5">Webinterface</h3>
                  <p>
                    Allows easy setup and maintenance of users and datasources.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-0">
              <div className="single-service p-lg-5 p-4 text-center mt-3 border-bottom border-end">
                <div className="service-icon icon-center">
                  <img
                    src="/assets/img/service/app-development.png"
                    alt="service icon"
                    width="65"
                    height="65"
                  />
                </div>
                <div className="service-info-wrap">
                  <h3 className="h5">IP Whitelisting</h3>
                  <p>
                    Restrict access to your data to specific IP addresses.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-0">
              <div className="single-service p-lg-5 p-4 text-center mt-3 border-bottom">
                <div className="service-icon icon-center">
                  <img
                    src="/assets/img/service/shield.png"
                    alt="service icon"
                    width="65"
                    height="65"
                  />
                </div>
                <div className="service-info-wrap">
                  <h3 className="h5">Logging</h3>
                  <p>
                    A full audit log of all user activity and file transfers.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-0">
              <div className="single-service p-lg-5 p-4 text-center border-bottom border-end">
                <div className="service-icon icon-center">
                  <img
                    src="/assets/img/service/curve.png"
                    alt="service icon"
                    width="65"
                    height="65"
                  />
                </div>
                <div className="feature-info-wrap">
                  <h3 className="h5">Easy onboarding</h3>
                  <p>
                    Easily onboard new users or storage backends to the platform.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-0">
              <div className="single-service p-lg-5 p-4 text-center border-bottom border-end">
                <div className="service-icon icon-center">
                  <img
                    src="/assets/img/service/graphic-design.png"
                    alt="service icon"
                    width="65"
                    height="65"
                  />
                </div>
                <div className="feature-info-wrap">
                  <h3 className="h5">Multiple protocols</h3>
                  <p>
                    Support filetransfers through FTP, SFTP, FTPS and HTTPS.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-0">
              <div className="single-service p-lg-5 p-4 border-bottom text-center">
                <div className="service-icon icon-center">
                  <img
                    src="/assets/img/service/promotion.png"
                    alt="service icon"
                    width="65"
                    height="65"
                  />
                </div>
                <div className="feature-info-wrap">
                  <h3 className="h5">API</h3>
                  <p>
                    Automate your user/datasource management through the API.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 p-0">
              <div className="single-service p-lg-5 p-4 text-center border-end border-bottom">
                <div className="service-icon icon-center">
                  <img
                    src="/assets/img/service/coding.png"
                    alt="service icon"
                    width="65"
                    height="65"
                  />
                </div>
                <div className="feature-info-wrap">
                  <h3 className="h5">Easy setup</h3>
                  <p>
                    Register and start using your SFTP server in minutes.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-0">
              <div className="single-service p-lg-5 p-4 text-center border-end">
                <div className="service-icon icon-center">
                  <img
                    src="/assets/img/service/shield.png"
                    alt="service icon"
                    width="65"
                    height="65"
                  />
                </div>
                <div className="feature-info-wrap">
                  <h3 className="h5">SSL Certificates</h3>
                  <p>
                    Automate the renewal of your SSL certificates through Let's encrypt.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 p-0">
              <div className="single-service p-lg-5 p-4 text-center border-bottom">
                <div className="service-icon icon-center">
                  <img
                    src="/assets/img/service/app-development.png"
                    alt="service icon"
                    width="65"
                    height="65"
                  />
                </div>
                <div className="feature-info-wrap">
                  <h3 className="h5">Dashboard</h3>
                  <p>
                    Keep an overview of your data and users with the dashboard.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 p-0">
              <div className="single-service p-lg-5 p-4 text-center border-end border-top border-start">
                <div className="service-icon icon-center">
                  <img
                  src="/assets/img/service/coding.png"
                    alt="service icon"
                    width="65"
                    height="65"
                  />
                </div>
                <div className="feature-info-wrap">
                  <h3 className="h5">Custom branding</h3>
                  <p>
                    Apply your branding, logo, company-colors and subodmain to the portal.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 p-0">
              <div className="single-service p-lg-5 p-4 text-center border-end border-top border-start">
                <div className="service-icon icon-center">
                  <img
                    src="/assets/img/service/app-development.png"
                    alt="service icon"
                    width="65"
                    height="65"
                  />
                </div>
                <div className="feature-info-wrap">
                  <h3 className="h5">Filesharing</h3>
                  <p>
                    Share your files publicly with a secure URL. Deadlines can be applied to the shared URLs.
                  </p>
                </div>
              </div>
            </div>

          </div>

          <div className='row justify-content-center'>

          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureOne;
