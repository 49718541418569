import React from 'react';
import PageMeta from '../components/common/PageMeta';
import CtaFive from '../components/cta/CtaFive';

import FeatureFive from '../components/features/FeatureFive';
import Process from '../components/process/Process';
import TestimonialThree from '../components/testimonial/TestimonialThree';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import HeroEightCustom from './HeroEightCustom';
import FeaturesOne from "../components/managedfiletransfer/featuresone";
import FeaturesTwo from "../components/managedfiletransfer/featurestwo";
import FeaturesThree from "../components/managedfiletransfer/featuresthree";
import TrialBanner from "../components/trial/trialbanner";



const HomeAppTwo = () => {
    return (
        <Layout>
            <PageMeta title='Managed SFTP File Transfer | FilePulse' description="Simplify and secure your file transfers with our fully managed SFTP server solution. Our Managed SFTP Server allows businesses to securely exchange files over the internet without the complexities of managing infrastructure, software updates, or security protocols." />
            <Navbar navDark />
            <HeroEightCustom >
                Managed SFTP 
            </HeroEightCustom>
            <FeaturesOne />
            <FeaturesTwo />
            <FeaturesThree />
            <TrialBanner />
            <FooterOne footerLight />
        </Layout>
    );
};

export default HomeAppTwo;
