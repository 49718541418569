import React from 'react';
import CtaTwo from '../components/cta/CtaTwo';
import FeatureOne from '../components/features/FeatureOne';
import TestimonialTwo from '../components/testimonial/TestimonialTwo';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import PageMeta from '../components/common/PageMeta';
import HeroEightCustom from './HeroEightCustom';
import CtaFive from '../components/cta/CtaFive';
import Pricing from '../components/pricing/Pricing';
import Faq from '../components/faq/FaqTwo';
import BlogDetails from "../components/blog/BlogGrid"

const PricingApp = () => {
    return (
        <Layout>
            <PageMeta title='Blog | FilePulse' description="Stay informed with our latest blog posts, featuring expert insights, tips, and updates on Sftp solutions and trends" />
            <Navbar navDark />
            <HeroEightCustom >
                Blog
            </HeroEightCustom>

            <BlogDetails />

            <FooterOne footerLight />
        </Layout>
    );
};

export default PricingApp;