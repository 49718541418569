import React from 'react';
import { Link } from 'react-router-dom';


const FeatureFive = () => {
  return (
    <>
      <section className='image-feature pt-60 pb-120'>
        <div className='container'>
          <div className='row justify-content-between align-items-center'>
            <div className='col-lg-5 col-12 order-lg-1'>
              <div className='feature-img-content'>
                <div className='icon-box rounded-custom bg-dark shadow-sm d-inline-block'>
                </div>

                <p>
                Streamline your file transfers with our fully managed SFTP server solution. Designed for businesses, our fully managed SFTP Server enables secure file exchanges over the internet without the hassle of managing infrastructure, software updates, or security protocols. Whether collaborating with clients or internal teams, our service delivers top-tier security, compliance, and reliability—letting you focus on growing your business while we handle the technical complexities.
                </p>


              </div>
            </div>
            <div className='col-lg-6 col-12 order-lg-0'>
              <div className='feature-img-holder mt-4 mt-lg-0 mt-xl-0'>
                <div className='testimonial-tab-content mb-5 mb-lg-0 mb-md-0'>
                  <h2>Secure, Reliable Filetransfer solution that scales with your needs.</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='app-two-feature ptb-120'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-8 col-md-10'>
              <div className='section-heading text-center'>
                <h1>Key features</h1>
                <p>
                  For a complete list of features see; <Link to='/features'>Features</Link>
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='col-12 col-xl-10'>
              <div className='row'>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    {/* <div className='app-two-single-feature-icon box-one me-3 mb-4 mb-md-0'>
                      <i className='far fa-file-edit'></i>
                    </div> */}
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Custom storage integration</h3>
                      <p>
                        Connect to your own cloud storage provider, such as AWS S3, Google Cloud Storage, Dropbox, Microsoft Sharepoint, Azure Blob Storage.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    {/* <div className='app-two-single-feature-icon box-two me-3 mb-4 mb-md-0'>
                      <i className='far fa-bell'></i>
                    </div> */}
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Easy setup</h3>
                      <p>
                        Access your SFTP server within minutes after signing up. Our fully managed service is scalable, secure and simple to setup.
                      </p>

                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    {/* <div className='app-two-single-feature-icon box-three me-3 mb-4 mb-md-0'>
                      <i className='far fa-pen-nib'></i>
                    </div> */}
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>On-premise solution</h3>
                      <p>
                        Our software can be used on-premise or as a managed service. We offer a range of deployment options to suit your requirements.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-xl-6 col-lg-6 col-md-12'>
                  <div className='app-two-single-feature d-md-flex align-items-start mb-30'>
                    {/* <div className='app-two-single-feature-icon box-four me-3 mb-4 mb-md-0'>
                      <i className='far fa-cloud-download-alt'></i>
                    </div> */}
                    <div className='app-two-single-feature-content'>
                      <h3 className='h5'>Advanced security features</h3>
                      <p>
                        Supports encrypted file transfers and granular access controls. With access to a full audit trail of all user activity and data transfers.
                      </p>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="brand-logo pb-110">
        <div className="container container-comp">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-10">
              <div className="section-heading text-center">
                <h2>Trusted by 100+ organisations to transfer their data</h2>
              </div>
            </div>
          </div>
          <div className="row pb-60">
            <ul className="brand-logo-grid list-unstyled">
              <li>
                <img src="/assets/img/brand-logo/11.png" alt="brand logo" />
              </li>
              <li>
                <img src="/assets/img/brand-logo/12.png" alt="brand logo" />
              </li>
              <li>
                <img src="/assets/img/brand-logo/13.png" alt="brand logo" />
              </li>
              <li>
                <img src="/assets/img/brand-logo/14.png" alt="brand logo" />
              </li>
              <li>
                <img src="/assets/img/brand-logo/15.png" alt="brand logo" />
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeatureFive;