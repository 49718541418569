import React from 'react';
import PageMeta from '../components/common/PageMeta';
import CtaFive from '../components/cta/CtaFive';
import CtaFour from '../components/cta/CtaFour';

import FeatureFive from '../components/features/FeatureFive';
import Process from '../components/process/Process';
import TestimonialThree from '../components/testimonial/TestimonialThree';
import FooterOne from '../layout/Footer/FooterOne';
import SecureFileTransfer from '../components/securefilesharing/featuresone';
import FeaturesTwo from "../components/cloudsftpserver/featurestwo";
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import HeroEight from './HeroEight';

const HomeAppTwo = () => {
  return (
    <Layout>
      <PageMeta title='FilePulse | Managed SFTP File Transfer' description="FilePulse SFTP Server | Provides secure file sharing between your teams, partners and cloud infrastructure. | Start your 14-day free trial." />
      <Navbar navDark />
      <HeroEight />
      <FeatureFive />
      <SecureFileTransfer />
      <FeaturesTwo />
      <CtaFive />
      <FooterOne footerLight />
    </Layout>
  );
};

export default HomeAppTwo;
