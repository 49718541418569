import React from 'react';
import PageMeta from '../components/common/PageMeta';
import CtaFive from '../components/cta/CtaFive';

import FeatureFive from '../components/features/FeatureFive';
import Process from '../components/process/Process';
import TestimonialThree from '../components/testimonial/TestimonialThree';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import HeroEightCustom from './HeroEightCustom';
import FeaturesOne from "../components/cloudsftpserver/featuresone";
import FeaturesTwo from "../components/cloudsftpserver/featurestwo";
import FeaturesThree from "../components/cloudsftpserver/featuresthree";
import TrialBanner from "../components/trial/trialbanner";


const HomeAppTwo = () => {
    return (
        <Layout>
            <PageMeta title='Cloud SFTP Server | FilePulse' description="Set up a secure and scalable cloud-based SFTP server for efficient file transfers, enhanced security, and seamless collaboration across your organization." />
            <Navbar navDark />
            <HeroEightCustom>
                Cloud SFTP Server
            </HeroEightCustom>
            <FeaturesOne />
            <FeaturesTwo />
            <FeaturesThree />
            <TrialBanner />
            <FooterOne footerLight />
        </Layout>
    );
};

export default HomeAppTwo;
