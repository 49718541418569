import ProfileCard from "./../components/blog/ProfileCard"
import React from 'react';

import FooterOne from './../layout/Footer/FooterOne';
import Navbar from './../layout/Header/Navbar';
import Layout from './../layout/Layout';
import PageMeta from './../components/common/PageMeta';
import HeroEightCustom from './HeroEightCustom';

/*

Setting up an SFTP server, 

The process of setting up a SFTP server is different for each provider, below we show you step-by-step how to setup our 
FilePulse SFTP server. 

1. Signup




*/


const BlogDetails = () => {
    return (
        <>
            <Layout>
                <PageMeta title='Privacy Policy | FilePulse' description="Review our commitment to protecting your data with clear guidelines on how we collect, use, and safeguard your personal and business information." />
                <Navbar navDark />
                <HeroEightCustom>
                    Privacy Policy
                </HeroEightCustom>

                <section className='blog-details ptb-120'>
                    <div className='container'>
                        <div className='row justify-content-between'>
                            <div className='col-lg-8 pe-5'>
                                <div className='blog-details-wrap'>




                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <FooterOne footerLight />
            </Layout>
        </>
    );
};

export default BlogDetails;
