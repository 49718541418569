import React from 'react';
import PageMeta from '../components/common/PageMeta';
import CtaFive from '../components/cta/CtaFive';

import FeatureFive from '../components/features/FeatureFive';
import Process from '../components/process/Process';
import TestimonialThree from '../components/testimonial/TestimonialThree';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import HeroEightCustom from './HeroEightCustom';
import FeaturesOne from "../components/storagegateway/featuresone";
import FeaturesTwo from "../components/storagegateway/featurestwo";
import FeaturesThree from "../components/storagegateway/featuresthree";
import TrialBanner from "../components/trial/trialbanner";



const HomeAppTwo = () => {
    return (
        <Layout>
            <PageMeta title='SFTP Storage gateway | FilePulse' description="Bridge your on-premise systems to the cloud with our reliable storage gateway, enabling secure and efficient data transfer and access to cloud storage." />
            <Navbar navDark />
            <HeroEightCustom >
                Storage gateway
            </HeroEightCustom>
            <FeaturesOne />
            <FeaturesTwo />
            <FeaturesThree />
            <TrialBanner />
            <FooterOne footerLight />
        </Layout>
    );
};

export default HomeAppTwo;

/*

1)
Bridge the gap between your on-premises infrastructure and cloud storage with our advanced Storage Gateway solution. Whether you're looking to extend your existing 
data center to the cloud, move large volumes of data seamlessly, or hybridize your storage architecture, our Storage Gateway provides a smooth, secure, and efficient 
way to manage data across multiple environments.


2) Key features;
Hybrid Cloud Storage Integration: Our Storage Gateway enables seamless communication between on-premises applications and cloud storage providers like AWS, Azure, and 
Google Cloud. You can retain your local storage systems while taking full advantage of cloud elasticity and performance, creating a true hybrid environment.

Multi-Protocol Support: Whether you're using FTP, SFTP, HTTP, our Storage Gateway supports multiple file and block protocols for easy integration 
with your existing IT systems. It’s designed to work with a wide range of applications, making it simple to implement regardless of your infrastructure.

3) Self-hosted
Flexible Deployment Options – Managed and Self-Hosted Versions on Major Cloud Marketplaces
We understand that every business has unique needs when it comes to deploying storage solutions. That’s why we offer both managed and self-hosted versions of our Storage 
Gateway, giving you the flexibility to choose the deployment model that best fits your infrastructure and business requirements.

Managed Version: For businesses that want a fully supported, hassle-free experience, our managed Storage Gateway is available as a SASS solution.
Enjoy a fully managed service with 24/7 support, built-in scalability, and complete reliability, backed by our expert team.

Self-Hosted Version: For organizations that require more control over their environment, our self-hosted Storage Gateway is also available through these same marketplaces. You can deploy it within your own cloud account and manage the configuration, scaling, and maintenance yourself. This option provides greater flexibility for custom configurations and integrations with your existing infrastructure while still benefiting from the cloud's performance and scalability.




*/

