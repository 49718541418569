import ProfileCard from "./../components/blog/ProfileCard"
import React from 'react';

import FooterOne from './../layout/Footer/FooterOne';
import Navbar from './../layout/Header/Navbar';
import Layout from './../layout/Layout';
import PageMeta from './../components/common/PageMeta';
import HeroEightCustom from './HeroEightCustom';

/*

Setting up an SFTP server, 

The process of setting up a SFTP server is different for each provider, below we show you step-by-step how to setup our 
FilePulse SFTP server. 

1. Signup




*/


const BlogDetails = () => {
    return (
        <>
            <Layout>
                <PageMeta title='FilePulse | Terms of service' description="Review the terms and conditions governing your use of our services, ensuring clarity on your rights, responsibilities, and our service commitments." />
                <Navbar navDark />
                <HeroEightCustom>
                    Terms of service
                </HeroEightCustom>

                <section className='blog-details ptb-120'>
                    <div className='container'>
                        <div className='row justify-content-between'>
                            <div className='col-lg-8 pe-5'>
                                <div className='blog-details-wrap'>


                                    <h3>1. Introduction</h3>
                                    <p>Welcome to FilePulse. By using our cloud SFTP server (referred to as "Service"), you agree to comply with these Terms of Service ("Terms"). If you do not agree to these Terms, you must not use the Service.</p>


                                    <h3>2. Definitions</h3>
                                    <p>The term "Service" refers to the cloud SFTP server provided by FilePulse. The term "User" means any individual or entity accessing or using the Service. "Content" refers to any data, files, or materials you upload, download, or manage through the Service. "You" or "Your" refers to the User or the entity using the Service.
                                    </p>
                                    <h3>3. Eligibility</h3>
                                    <p>To use the Service, you must be at least 18 years old, provide accurate and complete information during registration, and comply with all applicable laws and regulations.
                                    </p>
                                    <h3>4. Account Registration and Security</h3>
                                    <p>You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. If you suspect unauthorized access or use of your account, you must notify us immediately.
                                    </p>
                                    <h3>5. Acceptable Use</h3>

                                    <p>When using the Service, you must comply with all applicable laws, these Terms, and our Acceptable Use Policy. By agreeing to this policy, you ensure that your activities on the Service are lawful, ethical, and do not harm the integrity of the Service or its users. Specifically, you agree to the following:
                                    </p>
                                    <b>Prohibited Uses</b>
                                    <p>Illegal Activities: You may not use the Service to engage in any activities that are illegal or unlawful under applicable local, state, national, or international laws. This includes but is not limited to:
                                    </p>
                                    <p> Uploading, downloading, or sharing content that violates intellectual property laws, privacy laws, or other legal rights.
                                        Facilitating or participating in illegal activities, such as fraud, human trafficking, or terrorism.
                                        Malicious Content: You must not upload, share, or transmit malicious software, viruses, trojans, worms, or other harmful code that could disrupt the Service, compromise other users' data, or harm third-party systems.
                                    </p>

                                    <p>Unauthorized Access: You may not attempt to gain unauthorized access to any systems, accounts, or networks connected to the Service. This includes:
                                    </p>

                                    <p>
                                        Attempting to bypass authentication measures.
                                        Hacking, phishing, or other activities aimed at compromising security.
                                        Harassment and Harmful Behavior: The Service may not be used to harass, defame, or threaten other users or individuals. This includes any activity that could cause physical, emotional, or financial harm.

                                    </p>

                                    <b>Content Restrictions:
                                        You may not store, share, or distribute the following types of content:</b>

                                    <p>
                                        Copyrighted material without proper authorization.
                                        Material that is pornographic, obscene, defamatory, hateful, or discriminatory.
                                        Any material that promotes violence, criminal activity, or misinformation.
                                    </p>
                                    <p>
                                        Resource Misuse
                                        Service Integrity: You may not perform any action that disrupts the normal functioning of the Service or consumes excessive resources in a way that negatively impacts other users. This includes:

                                        Abusing bandwidth or storage limits.
                                        Conducting Distributed Denial-of-Service (DDoS) attacks.
                                        Hosting or running automated scripts or bots that degrade system performance.
                                        Misuse of Features: Features of the Service, such as file sharing, must be used only for their intended purposes. Misusing features to engage in prohibited activities will be treated as a breach of this policy.

                                    </p>

                                    <h3>6. Service Availability</h3>
                                    <p>The Service is provided on an "as is" and "as available" basis. While we strive for high availability, we cannot guarantee that the Service will always be uninterrupted or error-free. Scheduled maintenance or updates may occasionally impact availability, and we will provide advance notice whenever possible.
                                    </p>
                                    <h3>7. Data Ownership and Privacy</h3>
                                    <p>You retain full ownership of any Content you upload or manage through the Service. By using the Service, you grant us a limited license to store and transmit your Content solely for the purpose of delivering the Service.
                                    </p>
                                    <h3>8. Security</h3>
                                    <p>We use commercially reasonable measures to protect your data and maintain security. However, you are responsible for securing access to the Service, including managing your accounts, passwords, and any third-party integrations.
                                    </p>
                                    <h3>9. Fees and Payment</h3>
                                    <p>Details regarding subscription fees, billing cycles, and payment terms are outlined during the account registration process or as specified in your subscription plan. Failure to pay applicable fees may result in suspension or termination of your account.
                                    </p>
                                    <h3>10. Termination</h3>
                                    <p>You may terminate your account at any time. We also reserve the right to suspend or terminate your account if you violate these Terms or fail to pay applicable fees. Upon termination, your Content will be handled in accordance with our Data retention policy.
                                    </p>
                                    <h3>11. Intellectual Property</h3>
                                    <p>The Service, including its software, logos, and trademarks, is the property of FilePulse. We grant you a limited, non-exclusive license to use the Service for its intended purpose.
                                    </p>
                                    <h3>12. Limitation of Liability</h3>
                                    <p>To the fullest extent allowed by law, FilePulse will not be liable for any direct, indirect, incidental, or consequential damages resulting from your use of the Service. This includes loss of data or unauthorized access to your Content. Our total liability is limited to the amount you have paid for the Service during the last 12 months.
                                    </p>
                                    <h3>13. Indemnification</h3>
                                    <p>You agree to indemnify and hold FilePulse harmless from any claims, damages, or liabilities arising from your use of the Service, your violation of these Terms, or any third-party claims related to your Content.
                                    </p>
                                    <h3>14. Modifications to the Terms</h3>
                                    <p>We may modify these Terms at any time. Any changes will take effect as soon as they are posted. By continuing to use the Service after such changes, you agree to the updated Terms.
                                    </p>
                                    <h3>15. Contact Information</h3>
                                    <p>If you have any questions or concerns about these Terms, you can contact us at:
                                        FilePulse
                                        Email: hello@filpulse.io</p>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <FooterOne footerLight />
            </Layout>
        </>
    );
};

export default BlogDetails;
