import React from 'react';
import CtaTwo from '../components/cta/CtaTwo';
import FeatureOne from '../components/features/FeatureOne';
import TestimonialTwo from '../components/testimonial/TestimonialTwo';
import FooterOne from '../layout/Footer/FooterOne';
import Navbar from '../layout/Header/Navbar';
import Layout from '../layout/Layout';
import PageMeta from '../components/common/PageMeta';
import HeroEight from './HeroEightCustom';
import CtaFive from '../components/cta/CtaFive';
import Pricing from '../components/pricing/Pricing';

const PricingApp = () => {
  return (
    <Layout>
      <PageMeta title='Managed SFTP Cloud server pricing | FilePulse' description="Discover flexible and affordable pricing plans for our cloud-based file transfer and storage solutions, tailored to suit businesses of all sizes" />
      <Navbar navDark />
      <HeroEight>
        Pricing
      </HeroEight>

      <Pricing />

      <FooterOne footerLight />
    </Layout>
  );
};

export default PricingApp;
