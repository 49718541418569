
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Features from "../pages/Features";
import Pricing from "../pages/Pricing";
import Faq from "../pages/Faq";
import Blog from "../pages/Blog";

import CloudStorageGateway from "../pages/cloudStorageGateway";
import ManagedSftpServer from "../pages/managedSftpServer";
import SecureFileSharing from "../pages/secureFileSharing";
import SelfHostedDataTransferSolution from "../pages/selfHostedDataTransferSolution";

import CloudSftpGateway from "../pages/cloudSftpServer";
import ManagedFileTransfer from "../pages/managedFileTransfer";
import StorageGateway from "../pages/storageGateway";

import WhatIsMft from "../pages/WhatIsMft"
import WhatIsSftp from "../pages/WhatIsSftp";
import CheaperAwsTransferAlternatives from "../pages/CheaperAwsTransferAlternatives";
import AccessingASftpServer from "../pages/AccessingASftpServer";
import BenefitsCloudSftpServer from "../pages/BenefitsCloudSftpServer";
import HowToSetupSftpServer from "../pages/HowToSetupSftpServer";
import MigratingToCloudSftpServer from "../pages/MigratingToCloudSftpServer";

import TermsOfService from "../pages/TermsOfService";
import PrivacyPolicy from "../pages/PrivacyPolicy";

import RequestDemo from "../pages/RequestDemo";
import Home from "../pages/Home";

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga'

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/features/" element={<Features />} />
        <Route exact path="/pricing/" element={<Pricing />} />
        <Route exact path="/faq/" element={<Faq />} />
        <Route exact path="/blog/" element={<Blog />} />
        <Route exact path="/contact/" element={<RequestDemo />} />

        <Route exact path="/post/what-is-mft/" element={<WhatIsMft />} />
        <Route exact path="/post/accessing-a-sftp-server/" element={<AccessingASftpServer />} />
        <Route exact path="/post/benefits-cloud-sftp-server/" element={<BenefitsCloudSftpServer />} />
        <Route exact path="/post/how-to-setup-sftp-server/" element={<HowToSetupSftpServer />} />
        <Route exact path="/post/migrating-to-cloud-sftp-server/" element={<MigratingToCloudSftpServer />} />
        <Route exact path="/post/what-is-sftp/" element={<WhatIsSftp/> } />
        <Route exact path="/post/cheaper-aws-transferfamily-alternatives" element={<CheaperAwsTransferAlternatives />} />

        <Route exact path="/solutions/cloud-storage-gateway/" element={<CloudStorageGateway />} />
        <Route exact path="/solutions/managed-sftp-server/" element={<ManagedSftpServer />} />
        <Route exact path="/solutions/secure-file-sharing/" element={<SecureFileSharing />} />
        <Route exact path="/solutions/self-hosted-data-transfer-solution/" element={<SelfHostedDataTransferSolution />} />

        <Route exact path="/products/cloud-sftp-server/" element={<CloudSftpGateway />} />
        <Route exact path="/products/managed-file-transfer/" element={<ManagedFileTransfer />} />
        <Route exact path="/products/storage-gateway/" element={<StorageGateway />} />

        <Route exact path="/terms-of-service/" element={<TermsOfService />} />
        <Route exact path="/privacy-policy/" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
